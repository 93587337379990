import { ButtonPrimaryDark } from "@components/buttons";
import styled, { css } from "styled-components";

import { AnnouncementInner } from "@anwb/announcement/dist/styles/announcement.styled";
import { DefaultThemeProps } from "@anwb/theme";

export const Button = styled(ButtonPrimaryDark).attrs({
  icon: "refresh",
  size: "small",
})``;

const serviceWorkerWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  ${AnnouncementInner} {
    padding: 1rem;
  }

  && ${Button} {
    color: ${theme.colors.buttons.textButtonPrimary};
  }
`;

export const ServiceWorkerWrapper = styled.div`
  ${serviceWorkerWrapperStyles};
`;
