import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useTrainerState } from "@components/Trainer/TrainerLayout/hooks";
import {
  ContentText,
  StyledButtonWrapper,
  StyledContainer,
  TitleText,
} from "@components/Trainer/TrainerSegmentIntroduction/styles/TrainerSegmentIntroduction.styled";
import {
  ArrowIcon,
  ButtonPrimaryDarkIconRight,
  ButtonTertiaryDarkIconLeft,
} from "@components/buttons";
import { assertIsDefined } from "@domain/utils";

import Nl2br from "@topgun/shared/src/components/Nl2br";

type TrainerUrlProps = {
  configurationId: string;
  dynamicActivityId: string;
  segmentId: string;
};

const TrainerSegmentIntroduction: FunctionComponent = () => {
  const { configurationId, dynamicActivityId, segmentId } = useParams<TrainerUrlProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");
  assertIsDefined(segmentId, "Unknown segment ID");

  const { dynamicActivity } = useTrainerState();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const segmentData = useMemo(
    () => dynamicActivity?.segments?.find((segment) => segment.id === segmentId),
    [dynamicActivity?.segments, segmentId],
  );

  const goToPrevPage = () => {
    navigate(`/trainers/${configurationId}/${dynamicActivityId}/status/${segmentId}`);
  };

  const goToNextPage = () => {
    const nextPage = dynamicActivity?.pages.find((page) => page.segment_id === segmentId);
    const nextPageId = nextPage?.id;
    navigate(`/trainers/${configurationId}/${dynamicActivityId}/page/${nextPageId}`);
  };

  return (
    <StyledContainer>
      <TitleText tagName="h1" variant="content-title">
        {t("trainerSegments.introductionTitle")} <br />
        {segmentData?.title}
      </TitleText>

      <ContentText variant="body-text">
        <Nl2br>{segmentData?.content}</Nl2br>
      </ContentText>

      <StyledButtonWrapper>
        <ButtonTertiaryDarkIconLeft
          icon={ArrowIcon.LEFT}
          iconPosition="before"
          onClick={goToPrevPage}
        >
          {t("trainerSegments.introductionButtonBack")}
        </ButtonTertiaryDarkIconLeft>
        <ButtonPrimaryDarkIconRight icon={ArrowIcon.RIGHT} onClick={goToNextPage}>
          {t("trainerSegments.introductionButtonStart", {
            segmentTitle: segmentData?.title.toLowerCase(),
          })}
        </ButtonPrimaryDarkIconRight>
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

export default TrainerSegmentIntroduction;
