import { useTranslation } from "react-i18next";

import useServiceWorker from "@hooks/useServiceWorker";

import { Announcement } from "@anwb/announcement";
import Icon from "@anwb/icon";
import Typography from "@anwb/typography";

import { Button, ServiceWorkerWrapper } from "./styles";

const ServiceWorker = () => {
  const { hasUpdate, update } = useServiceWorker();
  const { t } = useTranslation();

  return (
    <ServiceWorkerWrapper>
      {hasUpdate && (
        <Announcement icon={<Icon variant="circle-closed" />}>
          <Typography>
            {t("notifications.updateAvailable.text")}{" "}
            <Button onClick={update}>{t("notifications.updateAvailable.installLabel")}</Button>
          </Typography>
        </Announcement>
      )}
    </ServiceWorkerWrapper>
  );
};

export default ServiceWorker;
