import { FunctionComponent, ReactNode } from "react";

import {
  AUTHORIZATION_ENDPOINT,
  AUTH_AUTHORITY,
  AUTH_CLIENT_ID,
  AUTH_LOG_OUT_URI,
  AUTH_REDIRECT_URI,
  SIGN_OUT_URL,
  TOKEN_ENDPOINT,
} from "@utils/constants";
import { OidcMetadata } from "oidc-client-ts";
import { AuthProvider, AuthProviderProps, Log } from "oidc-react";

if (process.env.NODE_ENV === "development") {
  Log.setLevel(Log.INFO);
  Log.setLogger(console);
}

const reloadOnPersistedNavigationHandler = (event: PageTransitionEvent) => {
  if (event.persisted) {
    window.location.reload();
  }
};

export const onBeforeSignIn = () => {
  addPageshowHandler();
  return window.location.pathname + window.location.search + window.location.hash;
};

const addPageshowHandler = () => {
  // This app will start a navigation to identity provider and some browser may store
  // current page (just before navigation) in page cache. If the user then uses the back button,
  // the cached version including the JavaScript state will be restored, which
  // means the user will not be signed in for instance, but only see a permanent spinner.
  // Fix this by listening to event that signals using a page from page cache and
  // force reload. That will trigger the sign-in flow again.
  window.addEventListener("pageshow", reloadOnPersistedNavigationHandler);
};

const authProviderProps: AuthProviderProps = {
  onBeforeSignIn,
  onSignOut: () => {
    addPageshowHandler();
    window.location.href =
      SIGN_OUT_URL ??
      `${AUTH_AUTHORITY}/logout?client_id=${AUTH_CLIENT_ID}&logout_uri=${encodeURIComponent(
        AUTH_LOG_OUT_URI,
      )}`;
  },
  autoSignIn: false,
  authority: AUTH_AUTHORITY,
  clientId: AUTH_CLIENT_ID,
  redirectUri: AUTH_REDIRECT_URI,
  loadUserInfo: false,
  scope: "openid profile",
  automaticSilentRenew: true,
};

const metadata: Partial<OidcMetadata> = {
  authorization_endpoint: AUTHORIZATION_ENDPOINT ?? `${AUTH_AUTHORITY}/authorize`,
  token_endpoint: TOKEN_ENDPOINT ?? `${AUTH_AUTHORITY}/token`,
};

interface Props {
  children: ReactNode;
}

export const AuthProviderWithConfig: FunctionComponent<Props> = ({ children }) => {
  return (
    <AuthProvider metadata={metadata} {...authProviderProps}>
      {children}
    </AuthProvider>
  );
};
