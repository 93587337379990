import { NavigationButtonIcon, SecondaryNavigationButtonIcon } from "@components/buttons";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`;

export const MobileBackButton = styled(SecondaryNavigationButtonIcon)`
  margin-inline: 1rem auto;
`;

export const MobileNextButton = styled(SecondaryNavigationButtonIcon)`
  margin-inline: auto 1rem;
`;

export const MobileNavigationButtonWrapper = styled.div`
  position: fixed;
  inset: auto 0 1rem;
  display: flex;
  z-index: 2;
  pointer-events: none;

  ${NavigationButtonIcon} {
    margin-block-start: 1rem;
    pointer-events: auto;
  }

  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

const navigationButtonWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  height: 3rem;
  width: 3rem;
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

export const NavigationButtonWrapper = styled.div`
  ${navigationButtonWrapperStyles}
`;

const playerContainerStyles = ({ theme }: DefaultThemeProps) => css`
  // Header height is 5 rem
  max-height: calc(var(--unit-100vh) - 5rem);
  height: calc(var(--unit-100vh) - 5rem);
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    position: relative;
    inset: 0;
    padding-inline: 2rem;
    width: 100%;

    // Header height changes to 6 rem on small viewport
    max-height: calc(var(--unit-100vh) - 6rem);
    height: calc(var(--unit-100vh) - 6rem);
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    max-width: 80rem;
    margin-inline: auto;

    // General page layout get 3 rem bottom padding on large viewports, so add 3 rem
    max-height: calc(var(--unit-100vh) - 9rem);
    height: calc(var(--unit-100vh) - 9rem);
  }
`;

export const PlayerContainer = styled.div`
  ${playerContainerStyles};
`;
