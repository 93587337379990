import styled, { css } from "styled-components";

import Grid from "@anwb/grid";
import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

const CHAPTER_BACKGROUND_WRAPPER_TOP_MOBILE = -75;

export const resultContainerStyles = ({ theme }: DefaultThemeProps) => css`
  background: linear-gradient(180deg, #001b3c 0%, #060e1b 86.78%);
  height: 100vh;
  padding-top: 6rem;
  position: absolute;
  top: 0;
  width: 100vw;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    top: ${pxToRem(CHAPTER_BACKGROUND_WRAPPER_TOP_MOBILE)};
  }
`;

export const ResultsBackground = styled.div<DefaultThemeProps>`
  ${resultContainerStyles};
`;

const contentWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  margin: 0 auto;
  align-items: center;
  max-width: 80rem;
  width: 100%;
  padding: 4rem 2rem;
  text-align: center;
  min-height: 100%;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: column;
    padding-top: 6rem;
    padding-inline: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  ${contentWrapperStyles};
`;

export const CheckOutGridItem = styled(Grid.Item)`
  align-self: center;
`;

export const ResultAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem;

  &&& {
    padding: 1.5rem;
  }
`;
