import { useCallback, useEffect, useRef, useState } from "react";

import type { Workbox } from "workbox-window";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

const CHECK_UPDATE_INTERVAL = 60 * 60 * 1000; // ms

export default () => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>(
    undefined,
  );
  const [hasUpdate, setHasUpdate] = useState(false);
  const wb = useRef<Workbox | null>(null);

  useEffect(() => {
    const waitingListener = () => {
      setHasUpdate(true);
    };
    const controllingListener = () => {
      window.location.reload();
    };

    let updateInterval: NodeJS.Timer | undefined;

    const register = async () => {
      if (!("serviceWorker" in navigator) || process.env.NODE_ENV === "development") {
        return;
      }
      try {
        const { Workbox } = await import("workbox-window");
        // eslint-disable-next-line camelcase
        wb.current = new Workbox(`${__webpack_public_path__}sw.js`, { updateViaCache: "none" });

        const newRegistration = await wb.current.register();

        if (newRegistration?.waiting && newRegistration?.active) {
          // a new service worker is waiting while this site is already controlled
          setHasUpdate(true);
        }

        wb.current.addEventListener("waiting", waitingListener);
        wb.current.addEventListener("controlling", controllingListener);

        updateInterval = setInterval(() => {
          // For long-lived SPA sessions without page reload, check periodically for SW updates
          void wb.current?.update();
        }, CHECK_UPDATE_INTERVAL);

        setRegistration(newRegistration);
      } catch (err) {
        NewRelicHelper.logError(err);
        // eslint-disable-next-line no-console
        console.error("Failed to register service worker", err);
      }
    };

    void register();

    return () => {
      if (waitingListener != null) {
        wb.current?.removeEventListener("waiting", waitingListener);
      }
      if (controllingListener != null) {
        wb.current?.removeEventListener("waiting", controllingListener);
      }
      if (updateInterval != null) {
        clearInterval(updateInterval as NodeJS.Timeout);
      }
    };
  }, []);

  const update = useCallback(() => {
    setHasUpdate(false);
    wb.current?.messageSkipWaiting();
  }, []);

  return { registration, hasUpdate, update };
};
