import { FunctionComponent } from "react";

import { ClientProvider } from "@components/ClientProvider";
import { ConfigLoader } from "@components/ConfigLoader";
import { HeaderContextProvider } from "@components/HeaderContextProvider/HeaderContextProvider";
import ServiceWorker from "@components/ServiceWorker";
import { CustomFonts } from "@components/typography";
import Routes from "@routes/Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ANWBDarkBrandRefresh } from "@theme/dark-anwb-brand-refresh";
import "@utils/i18n";
import { ThemeProvider } from "styled-components";

import { Fonts } from "@anwb/theme";

import { ApplicationWrapper, GlobalStyle } from "./styles";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 30,
      useErrorBoundary: true,
    },
  },
});

const Application: FunctionComponent = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ThemeProvider theme={ANWBDarkBrandRefresh} data-testid="application">
      <Fonts />
      <CustomFonts />
      <GlobalStyle />
      <ServiceWorker />
      <ConfigLoader>
        <ClientProvider>
          <ApplicationWrapper>
            <HeaderContextProvider>
              <Routes />
            </HeaderContextProvider>
          </ApplicationWrapper>
        </ClientProvider>
      </ConfigLoader>
    </ThemeProvider>
  </QueryClientProvider>
);

export default Application;
