import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorDetails } from "@components/Activity/styles/activity.styled";
import CenteredSpinner from "@components/CenteredSpinner";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import Notification from "@components/Notification";
import Player from "@components/Player";
import { usePlayerState } from "@components/PlayerLayout";
import { DialogTextFor, MaterialType } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import useActivity from "@hooks/useActivity";
import useH5PPlayPrefetching from "@hooks/useH5PPlayPrefetching";
import { getNotificationStructure } from "@utils/helpers";

type ActivityUrlProps = {
  activityId: string;
  pageId: string;
};

const Activity: FunctionComponent = () => {
  const { activityId, pageId } = useParams<ActivityUrlProps>();
  assertIsDefined(activityId, "Unknown activity ID");

  const { clearNavigateBack, setNavigateBack, setBreadcrumbs } = useHeaderUpdater();
  const { activity, pages, isLoading } = useActivity(activityId);
  const { progress, setProgress } = usePlayerState();
  const navigate = useNavigate();

  const activePageId = pageId || pages?.[0]?.value.id;
  const foundPage =
    activePageId && pages ? pages.find((page) => page.value.id === activePageId) : undefined;
  const contentId = foundPage?.value.contentId;
  const pageIndex = pages?.findIndex((page) => page.value.id === activePageId) || 0;

  const nextPage = pages?.[pageIndex + 1];
  const nextContentId = nextPage?.value.contentId;

  useH5PPlayPrefetching(nextContentId);

  useEffect(() => {
    setProgress(pages ? [pageIndex + 1, pages.length] : undefined);
  }, [setProgress, pages, pageIndex]);

  useEffect(() => {
    const chapter = activity?.parent?.parent?.value;
    if (chapter) {
      setNavigateBack({
        to: `/chapters/${chapter.id}`,
        icon: "cross",
      });
    }
  }, [activity, setNavigateBack]);

  useEffect(
    () => () => {
      clearNavigateBack();
    },
    [clearNavigateBack],
  );

  useEffect(() => {
    if (activity) {
      const chapter = activity.parent?.parent?.value;
      if (chapter) {
        setBreadcrumbs([
          ...(chapter.sequenceLabel
            ? [
                {
                  label: `H.${chapter.sequenceLabel}`,
                  to: `/chapters/${chapter.id}`,
                },
              ]
            : []),
          {
            label: `${activity.value.sequenceLabel || ""} ${activity.value.title}`.trim(),
            to: `/activities/${activity.value.id}`,
            dialogTextFor: DialogTextFor.RESTART,
            disabled: progress && progress[0] === 1,
          },
        ]);
      }
    }
  }, [activity, setBreadcrumbs, progress]);

  const h5pMetadata = useMemo(() => {
    return {
      context: activityId,
      contextType: "ACTIVITY",
    };
  }, [activityId]);

  if (contentId) {
    const goToPreviousPage = () => {
      if (!pages?.length || pageIndex === 0) {
        return;
      }

      const previousPage = pages[pageIndex - 1];
      const { id: previousPageId } = previousPage.value;

      navigate(`/activities/${activityId}/page/${previousPageId}`);
    };

    const goToNextPage = () => {
      if (!pages?.length) {
        return;
      }
      if (pages && pageIndex === pages.length - 1) {
        navigate(`/activities/${activityId}/checkout`);
        return;
      }
      const nextPage = pages[pageIndex + 1];
      const { id: nextPageId } = nextPage.value;

      navigate(`/activities/${activityId}/page/${nextPageId}`);
    };

    return (
      <Player
        contentId={contentId}
        pageIndex={pageIndex}
        onPreviousClick={goToPreviousPage}
        h5pInstanceMetadata={h5pMetadata}
        onNextClick={goToNextPage}
      />
    );
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  const error = getNotificationStructure(MaterialType.ACTIVITY);
  return (
    <Notification $alignNotification variant={error.variant} title={error.title}>
      {error.children}
      {pages?.length === 0 && <ErrorDetails>EMPTY_ACTIVITY: {activityId}</ErrorDetails>}
    </Notification>
  );
};

export default Activity;
