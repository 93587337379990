import { StyleRefreshWrapper } from "@components/Player/H5PPlayerWrapper/styles/h5p-player-wrapper.styled";
import { ButtonWrapper } from "@components/Trainer/ImageHeader/styles";
import { BodyL } from "@components/typography";
import HeadingCompressedBoldL from "@components/typography/HeadingCompressedBoldL";
import styled from "styled-components";

export const TitleText = styled(HeadingCompressedBoldL)`
  margin: 1rem 0 2rem;
`;

export const ContentText = styled(BodyL)`
  margin: 0 0 1rem;
`;

export const StyledContainer = styled(StyleRefreshWrapper)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
`;

export const StyledButtonWrapper = styled(ButtonWrapper)`
  flex-grow: 0;
  margin-top: auto;

  > button {
    margin-top: 20px;
  }
`;
