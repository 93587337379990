import React, { FunctionComponent, memo, useCallback } from "react";
import { createPortal } from "react-dom";

import CenteredSpinner from "@components/CenteredSpinner";
import H5PPlayerWrapper from "@components/Player/H5PPlayerWrapper";
import {
  MobileBackButton,
  MobileNavigationButtonWrapper,
  MobileNextButton,
  NavigationButtonWrapper,
  PlayerContainer,
  PlayerWrapper,
} from "@components/Player/styles/Player.styles";
import { SecondaryNavigationButtonIcon } from "@components/buttons";
import { H5pInstance } from "@domain/types";
import useH5PPlay from "@hooks/useH5PPlay";
import { H5PPlayerUI } from "@lumieducation/h5p-react";
import { IPlayerModel } from "@lumieducation/h5p-server";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

interface Props {
  contentId: string;
  pageIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  h5pInstanceMetadata: Record<string, string>;
}

const H5PPlayer = memo(H5PPlayerUI);

const Player: FunctionComponent<Props> = ({
  contentId,
  pageIndex,
  onPreviousClick,
  onNextClick,
  h5pInstanceMetadata,
}) => {
  const head = document.getElementsByTagName("head")[0];
  const { data: h5pContent } = useH5PPlay(contentId);

  const contentType = h5pContent?.dependencies.map((dep) => dep.machineName)[0];

  // h5p-player requires an async method, but linter requires least one await. Can't satisfy both.
  // eslint-disable-next-line @typescript-eslint/require-await
  const handleLoadContent = useCallback(async (): Promise<IPlayerModel> => {
    if (!h5pContent) {
      throw new Error("Cannot load player without content");
    }

    // Fix H5P memory leak. H5P is not designed for SPAs.
    if (window.H5P !== undefined) {
      window.H5P.instances = [];
    }
    // Remove scripts that were loaded earlier for same content type, but which may be
    // for another version.
    Array.from(head.getElementsByTagName("script"))
      .filter((el) => contentType && el.dataset.h5pSrc?.includes(contentType))
      .forEach((sc) => {
        sc.remove();
      });

    // create a copy of 'integration.content' because H5P may mutate that object
    return {
      ...h5pContent,
      integration: {
        ...h5pContent.integration,
        contents: {
          ...h5pContent.integration.contents,
        },
      },
      embedTypes: ["div"],
    };
  }, [h5pContent]);

  const handledH5PPlayerInitialized = useCallback(
    (initializedContentId) => {
      window.H5P.error = (error: string) => {
        const contentIdInErrorMessage = error.match(
          /content id (?<uuid>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/,
        )?.groups?.uuid;

        NewRelicHelper.logError(
          contentIdInErrorMessage && contentIdInErrorMessage !== initializedContentId
            ? `A H5P error for non-active content id occurred: ${error}`
            : error,
        );
      };

      const instance: H5pInstance | undefined = window.H5P.instances.find(
        (element) => element.contentId === contentId,
      );
      if (!instance) {
        throw new Error("Instance not found.");
      }

      instance.metadata = {
        ...h5pInstanceMetadata,
      };
    },
    [contentId, h5pInstanceMetadata],
  );

  if (!h5pContent || !contentType) {
    return <CenteredSpinner />;
  }

  const hasPreviousPage = pageIndex !== 0;

  return (
    <>
      <PlayerContainer>
        <PlayerWrapper>
          <NavigationButtonWrapper>
            {hasPreviousPage && (
              <SecondaryNavigationButtonIcon $variant="previous" onClick={onPreviousClick} />
            )}
          </NavigationButtonWrapper>
          <H5PPlayerWrapper contentType={contentType}>
            <H5PPlayer
              key={contentId}
              contentId={contentId}
              loadContentCallback={handleLoadContent}
              onInitialized={handledH5PPlayerInitialized}
            />
          </H5PPlayerWrapper>
          <NavigationButtonWrapper>
            <SecondaryNavigationButtonIcon
              data-testid="next-page-button"
              $variant="next"
              onClick={onNextClick}
            />
          </NavigationButtonWrapper>
        </PlayerWrapper>
      </PlayerContainer>
      {createPortal(
        <MobileNavigationButtonWrapper>
          {hasPreviousPage && <MobileBackButton $variant="previous" onClick={onPreviousClick} />}
          <MobileNextButton $variant="next" onClick={onNextClick} />
        </MobileNavigationButtonWrapper>,
        document.body,
      )}
    </>
  );
};

export default Player;
