import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

import { getBackgroundPositionCssForImageWithFocalPoint } from "@topgun/author-front-end/src/utils/focalPoint";
import { AuthorImageProps } from "@topgun/shared/src/types/frontend";
import { scaleBynderImage } from "@topgun/shared/src/util/image";

type DefaultThemeProps = {
  theme: Theme;
};

type ChapterImageProps = {
  image?: AuthorImageProps;
};

const fullscreenChapterImageStyles = ({ image }: DefaultThemeProps & ChapterImageProps) => css`
  position: relative;
  min-height: 80vh;
  margin-top: -90px;
  padding-top: 100px;
  background-image: linear-gradient(
      90deg,
      rgba(0, 15, 34, 1) 0%,
      rgba(0, 0, 0, 0.25) 50%,
      rgba(0, 15, 34, 0.75) 100%
    ),
    url(${image && scaleBynderImage(image.url)});

  background-size: cover;
  ${getBackgroundPositionCssForImageWithFocalPoint(image)}

  background-repeat: no-repeat;
  background-blend-mode: darken;
`;

export const FullscreenChapterImage = styled.div<{ image?: AuthorImageProps }>`
  ${fullscreenChapterImageStyles}
`;

const fullscreenImageHeaderStyling = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  padding: 1rem;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const FullscreenImageHeader = styled.div`
  ${fullscreenImageHeaderStyling}
`;

const fullscreenChapterBlockStyling = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  width: 66.66%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  padding: 1.5rem;
  background-color: rgb(0 0 0 / 25%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 0.75rem;
  cursor: pointer;
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    width: 100%;
  }
`;

export const FullscreenChapterBlock = styled.div`
  ${fullscreenChapterBlockStyling}
`;

const fullscreenProgressBarWrapperStyles = () => css`
  padding: 0.5rem 0;
`;

export const FullscreenChapterProgressBarWrapper = styled.div`
  ${fullscreenProgressBarWrapperStyles}
`;
const fullscreenChapterHeaderStyles = () => css`
  display: flex;
  gap: 15px;
`;

export const FullscreenChapterHeader = styled.div`
  ${fullscreenChapterHeaderStyles}
`;
