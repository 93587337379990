import { customVariables } from "@theme/custom-variables";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

const H5P_PLAYER_WRAPPER_WIDTH = 430;

type H5PPlayerWrapperProps = {
  $wide?: boolean;
} & DefaultThemeProps;

const styleRefreshStyles = ({ theme, $wide = false }: H5PPlayerWrapperProps) => {
  const wideVideoPlayerStyles = $wide
    ? css`
        @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
          width: 100%;
          margin-inline: 2rem;
        }

        @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
          width: 65%;
        }
      `
    : "";

  return css`
    display: block;
    margin-inline: auto;
    position: relative;
    transition: width 0.2s;
    max-width: 100%;
    width: 100%;
    padding-block-end: 4rem;

    @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
      width: ${H5P_PLAYER_WRAPPER_WIDTH}px;
      padding: 1.5rem 0;
      overflow-y: auto;
      border-radius: 1.5rem;
      background-color: #001b3c;

      // Header height is 6 rem and header has bottom-padding of 1.5 rem.
      max-height: calc(var(--unit-100vh) - 6rem - 1.5rem);
      height: calc(var(--unit-100vh) - 6rem - 1.5rem);
    }

    @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
      // General page layout get 3 rem bottom padding on large viewports, so add 3 rem
      max-height: calc(var(--unit-100vh) - 6rem - 1.5rem - 3rem);
      height: calc(var(--unit-100vh) - 6rem - 1.5rem - 3rem);
    }

    .h5p-player-component-root > p {
      margin: 1rem;
    }

    ${wideVideoPlayerStyles}
  `;
};

export const StyleRefreshWrapper = styled.div<H5PPlayerWrapperProps>`
  ${styleRefreshStyles};
`;

const videoWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) and (orientation: landscape) {
    max-width: calc(100% - 18rem);
    max-height: calc(100vh - 8rem);
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: 100%;
    flex-grow: 1;
    padding: 0 1.5rem;
    border-radius: 0;
    background-color: ${customVariables.colors.primaryBackground};
  }
`;

export const VideoWrapper = styled(StyleRefreshWrapper)`
  ${videoWrapperStyles};
`;
